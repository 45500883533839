<template>
  <div>
    <h4>Todai 女子のうた</h4>
    <div class="small mb-3">video</div>

    <p class="small">
      東大女子の中には、高校生の頃に「東大を受ける」と言うと「”女の子なんだから”
      わざわざいい大学目指さなくてもいいんじゃないか。」と言われた経験がある人が多くいます。女性は勉強に励んだりキャリアを追い求める必要はなく、結婚することで幸せになれるという社会通念は、まだまだ残っています。<br />東大入学者のうち男性は8割以上です。卒業し、官僚になると、やはりそこでも男性が約7割です。男性主体の場では、男性中心の考え方や決定がされやすくなります。女性の活躍の機会や権利を奪う社会構造は、それ自身が再生産される構造になっています。<br />
      ”女の子なんだから”
      という理由で将来を制限されない未来が来て欲しいという願いを込めた作品です。
      <br />制作協力: Hisateru Okada（写真）, Keiko Yamada（音声, 映像）
    </p>
    <div class="small mb-3">
      VIDEO▶︎▶︎
      <span>Not open to the public</span>
    </div>
    <img src="@/assets/todai1.jpg" class="img-fluid" />
    <img src="@/assets/todai2.jpg" class="img-fluid" />
    <img src="@/assets/todai3.jpg" class="img-fluid" />
    <img src="@/assets/todai4.jpg" class="img-fluid" />
  </div>
</template>

<style>
img {
  margin-bottom: 10px;
}
</style>
